
import { channelSlug, incrementalStaticRegenerationRevalidate, storeId } from "../constants";
import { HomeView, homePageProductsQuery } from "../views/Home";
import { preprocessMetaPage } from "../components/utils/meta";
import { exhaustList, getSaleorApi } from "../components/utils/ssr";
import { translateCollectionList, translateProductList } from "../components/utils/translation";
import { locale } from "../constants";

export default HomeView;

export const getStaticProps = async () => {
  const { api, apolloClient } = await getSaleorApi();

  const categoryResponse = await exhaustList(
    api.collections.getList({
      first: 10,
      filter: {
          published: "PUBLISHED",
          channel: channelSlug,
          search: storeId + "--",
      },
      channel: channelSlug,
    })
  );

  const { data } = await apolloClient.query({
    query: homePageProductsQuery,
    variables: {
      productFilter: {
        isPublished: true,
        collections: categoryResponse.data.map(v => v.id),
        channel: channelSlug
      },
      collectionFilter: {
        search: storeId + "--",
        published: "PUBLISHED",
        channel: channelSlug,
      },
      channel: channelSlug,
      pageSlug: storeId + "-main-page"
    },
  });

  // Translate Product List
  data.products = await translateProductList(apolloClient, locale, data.products)

  // Translate Collection List
  data.collections = await translateCollectionList(apolloClient, locale, data.collections)

  data.page = preprocessMetaPage(data.page, []);

  return {
    revalidate: incrementalStaticRegenerationRevalidate,
    props: {
      data,
    },
  };
};
