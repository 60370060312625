
  import { productList } from "@saleor/sdk/lib/queries/products";
  import { baseProductFragment, productPricingFragment } from "@saleor/sdk/lib/fragments/products";
  
  import { useTypedQuery } from "../../components/graphql";
  import { channelSlug } from "../../constants";
  import { PRODUCTS_PER_PAGE } from "../../core/config";
  import { metaPageQuery } from "../../components/utils/meta";
  import gql from "graphql-tag";
  import {
    convertSortByFromString,
    convertToAttributeScalar,
  } from "../../core/utils";
  
  export const useProductsQuery = (
    filters,
    ids,
    options
  ) => {
    const { categoryId, collectionId } = ids;
    const { first } = options;
  
    const variables = {
      filter: {
        channel: channelSlug,
      },
      first: first || PRODUCTS_PER_PAGE,
      sortBy: convertSortByFromString(filters.sortBy),
    };
  
    return useTypedQuery(productList, {
      variables,
      fetchPolicy: "cache-and-network",
    });
  };
  

  export const homePageProductsQuery = gql`
  ${baseProductFragment}
  ${productPricingFragment}
  query HomePageProducts($pageSlug: String!, $productFilter: ProductFilterInput!, $collectionFilter: CollectionFilterInput!, $channel: String) {

    products(
      first: 12
      filter: $productFilter
      channel: $channel
    ) {
      edges {
        node {
          ...BaseProduct
          ...ProductPricingField
        }
      }
    }
    collections(first: 100, filter: $collectionFilter) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    ${metaPageQuery}
  }
`;