import Link from "next/link";
import React from "react";
import { generatePath } from "react-router";
import { paths } from "../../../paths";

import styles from "./index.module.scss";

export const CategoryList = ({ categories }) => (
  <>
    <div className={styles["category-list"]}>
      {categories.map((category) => {
        const { slug, name } = category;
        return (
          slug &&
          name && (
            <Link href={generatePath(paths.category, { slug })} key={slug} prefetch={false}>
              <a>
                <h4 className={styles["category"]}>{name}</h4>
              </a>
            </Link>
          )
        );
      })}
    </div>
  </>
);
