import React from "react";
import { RichTextEditorContent } from "../../components/atoms/RichTextEditorContent";
import { ProductList } from "../../components/organisms";
import useMediaQuery from "../../hooks/useMediaQuery";
import { FormattedMessage } from "react-intl";
import { CategoryList } from "../../components/organisms/CategoryList";
import styles from "./scss/index.module.scss";

export const Page = ({
  products,
  collections,
  bannerTitle,
  bannerDescription,
  bannerImage,
}) => {
  const isMobile = useMediaQuery(768);

  return (
    <div className={styles["home-page"]}>
      <div className={styles["hero"]}>
        <div className={"container " + styles.container}>
          <div>
            <div className={styles["hero-text"]}>
              <span className={styles["hero__title"]}>
                <h1>{bannerTitle}</h1>
              </span>
              <span className={styles["hero__description"]}>
                <RichTextEditorContent jsonData={bannerDescription} />
              </span>
            </div>
          </div>
          <div className={styles["hero-image"]}>
            <img src={bannerImage} alt="Banner" style={{width: isMobile ? "100%" : 500, height: isMobile ? "auto" : 333, objectFit: "cover"}} />
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className={"hero-title " + styles.title}><FormattedMessage id="commons.categories" defaultMessage="Categories"/></h2>
        <CategoryList categories={collections} />
      </div>
      <div className="container">
        <h2 className={"hero-title " + styles.title}><FormattedMessage id="commons.products" defaultMessage="Products"/></h2>
        <ProductList products={products} canLoadMore={false} />
      </div>
    </div>
  );
};
