import React, { useMemo } from "react";

import { MetaWrapper } from "../../components/Meta";
import { Layout } from "../../components/Layout";
import { prepareMetaPage } from "../../components/utils/meta";
import { prepareSEO } from "../../components/utils/seo";
import { Page } from "./Page";

export const HomeView = ({ data }) => {
  const [products, collections, meta, seo] = useMemo(() => {
    const meta = prepareMetaPage(data?.page);
    return [
      data?.products?.edges.map((e) => e.node) || [],
      data?.collections?.edges.map((e) => e.node) || [],
      meta,
      {...prepareSEO("default", meta), links: meta.banner_image ? [{rel: "preload", as: "image", href: meta.banner_image}] : []},
    ];
  }, []);

  return (
    <MetaWrapper meta={seo}>
      <Layout meta={meta}>
        <Page
          products={products}
          collections={collections}
          bannerTitle={meta.banner_title}
          bannerDescription={meta.banner_description}
          bannerImage={meta.banner_image}
        />
      </Layout>
    </MetaWrapper>
  );
};
